<template>
  <div class="animated fadeIn">
    <LoadingWidget v-if="initStatus != 1" :status="initStatus" @init="init" />

    <b-card v-else>
      <template v-slot:header>
        <h5>Barang Masuk</h5>
      </template>

      <b-row>
        <b-col md="3">
          <b-form-group label-for="filterInput" class="form">
            <label for="search">Search</label>
            <b-form-input v-model="filter.search" name="filterTable" id="filterInput" type="search"
              placeholder="Type to search"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group class="form" label-for="filterDate" label="Periode">
            <div class="d-flex">
              <b-form-input v-model="filter.fromDate" name="filterFromDateTarik" id="filterDate" type="date"
                class="mr-1"></b-form-input>
              <b-form-input v-model="filter.endDate" name="filterToDateTarik" type="date"></b-form-input>
            </div>
          </b-form-group>
        </b-col>
        <b-col md="4" align-self="center">
          <b-button class="mr-2" variant="pgiBtn" name="searchTableBtn" :disabled="loading == true"
            @click="onFilter(filter)">
            <i class="fa fa-search"></i>
          </b-button>
          <b-button variant="pgiBtn" name="resetTableBtn" :disabled="loading == true" @click="onReset()">Reset</b-button>
        </b-col>
      </b-row>

      <hr />



      <b-table bordered hover show-empty :busy="isBusy" :items="pengiriman" :fields="fields" :perPage="perPage" responsive
        class="mb-3">
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong> Loading data...</strong>
          </div>
        </template>
        <template v-slot:empty> Tidak ada data Barang Masuk. </template>
        <template v-slot:cell(status)="row">
          <b-badge variant="success" v-if="row.item.status == 3">Diterima</b-badge>
          <b-badge variant="primary" v-else-if="row.item.status == 2">Dikirim</b-badge>
          <b-badge variant="secondary" v-else-if="row.item.status == 1">Belum Diproses</b-badge>
          <b-badge variant="danger" v-else-if="row.item.status == 0">Selisih Barang</b-badge>
        </template>
        <template v-slot:cell(action)="row">
          <div class="no-wrap">
            <b-link :to="`${$route.path}/${row.item.id}/detail`">
              <b-button size="sm" class="mr-3 table-button view" v-b-tooltip.hover title="Detail"
                name="pengirimanViewBtn">
                <i class="fa fa-info"></i>
              </b-button>
            </b-link>
            <b-button size="sm" class="mr-3 table-button view" v-b-tooltip.hover title="Print" name="pengirimanViewBtn">
              <i class="fa fa-print"></i>
            </b-button>
          </div>
        </template>
      </b-table>

      <div class="pagination-wrapper d-flex align-items-center justify-content-between">
        <span class="ml-2">{{ totalViewText }}</span>
        <b-pagination class="mb-0" v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table"
          @input="onPageChange"></b-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>


export default {
  name: "pengiriman",

  data() {
    return {
      initStatus: 0,
      isBusy: true,
      input: {
        show: 10,
      },

      loading: false,
      isSubmitting: false,
      fields: [
        { key: "noInvoice", label: "No Invoice" },
        { key: "tanggal", label: "Tanggal" },
        { key: "pengirim", label: "Pengirim" },
        { key: "penerima", label: "Penerima" },
        { key: "totalBarang", label: "Total Barang" },
        { key: "status", label: "Status" },
        { key: "action", label: "Aksi" },
      ],
      filter: {
        search: "",
        fromDate: "",
        endDate: "",
      },
      options: [
        { value: 10, text: "10" },
        { value: 20, text: "20" },
        { value: 30, text: "30" },
        { value: 40, text: "40" },
        { value: 50, text: "50" },
      ],
      pengirimanAll: [],
      pengiriman: [],
      currentPage: 1,
      perPage: null,
      rows: null,
      totalViewText: "",
      page: "",
      size: "",
      from: "",
      limit: "",
      query: "",
    };
  },
  methods: {
    //----------------- Fungsi Inisialisasi -----------------

    init() {
      this.initStatus = 0;
      this.roleId = localStorage.getItem("role_id");

      let getPengiriman = this.$axios
        .get("api/admin/barang-masuk/barang-cabang-penerima")
        .then((response) => {
          let datas = response.data.data;

          for (var data of datas.data) {
            let penerima = "-";
            let pengirim = "-";
            if (data.cabang_penerima != null) {
              penerima =
                data.cabang_penerima.name != null
                  ? data.cabang_penerima.name
                  : data.cabang_penerima.nama_cabang;
            }
            if (data.cabang_pengirim != null) {
              pengirim =
                data.cabang_pengirim.name != null
                  ? data.cabang_pengirim.name
                  : data.cabang_pengirim.nama_cabang;
            }
            this.pengiriman.push({
              id: data.id,
              noInvoice: data.no_invoice,
              tanggal: data.tanggal,
              pengirim: pengirim,
              penerima: penerima,
              totalBarang: data.total_unit,
              status: data.status,
              user_input: data.user_input,
            });
          }

          this.perPage = datas.per_page;
          this.size = this.perPage;
          this.from = datas.from;
          this.limit = datas.to;
          this.loading = false;

          this.updateTotalItem(datas.total);
          let msg = this.$helper.getFlash();
          this.$helper.toastSucc(this, msg);
        });

      Promise.all([getPengiriman])
        .then(() => {
          this.initStatus = 1;
          let msg = this.$helper.getFlash();
          this.$helper.toastSucc(this, msg);
          this.toggleBusy();
        })
        .catch((error) => {
          console.log(error);
          this.initStatus = -1;
        });
    },

    getPengiriman() {
      this.toggleBusy();
      this.pengiriman = [];
      //console.log(this.filter.tipe);
      //console.log(this.filter.golongan);
      //if (this.filter.tipe != null) this.query = this.query + "&tipe=" + this.filter.tipe
      //if (this.filter.merk != null) this.query = this.query + "&id_kategori=" + this.filter.golongan

      this.$axios
        .get("api/admin/barang-masuk/barang-cabang-penerima" + this.query)
        .then((response) => {
          let datas = response.data.data;

          for (var data of datas.data) {
            let penerima = "-";
            let pengirim = "-";
            if (data.cabang_penerima != null) {
              penerima =
                data.cabang_penerima.name != null
                  ? data.cabang_penerima.name
                  : data.cabang_penerima.nama_cabang;
            }
            if (data.cabang_pengirim != null) {
              pengirim =
                data.cabang_pengirim.name != null
                  ? data.cabang_pengirim.name
                  : data.cabang_pengirim.nama_cabang;
            }
            this.pengiriman.push({
              id: data.id,
              noInvoice: data.no_invoice,
              tanggal: data.tanggal,
              pengirim: pengirim,
              penerima: penerima,
              totalBarang: data.total_unit,
              status: data.status,
              user_input: data.user_input,
            });
          }

          this.perPage = datas.per_page;
          this.size = this.perPage;
          this.from = datas.from;
          this.limit = datas.to;
          this.loading = false;

          this.toggleBusy();
          this.updateTotalItem(datas.total);
          let msg = this.$helper.getFlash();
          this.$helper.toastSucc(this, msg);
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response.data.reason);
          this.$helper.parseError(this, error);
          this.toggleBusy();
          this.loading = false;
        });
    },
    //----------------- Fungsi Pagination, Sorting, dan Filtering -----------------

    onPageChange() {
      if (this.loading == false) {
        console.log(this.currentPage);
        this.page = this.currentPage;
        this.query = "?page=" + this.page;

        this.getPengiriman();
      }
    },

    onLimitChange() {
      this.perPage = this.input.show;
      this.onPageChange();
    },

    onFilter(letters) {
      if (this.filter == null) {
        this.$helper.toastErr(
          this,
          "Silakan isi nama jenis barang terlebih dahulu!"
        );
      } else {
        let filteredArray = [];

        for (var x of this.pengirimanAll) {
          if (x.name.toLowerCase().includes(letters.toLowerCase())) {
            filteredArray.push(x);
          }
        }

        this.pengiriman = filteredArray;
      }
    },

    onReset() {
      this.filter.search = "";
      this.filter.fromDate = "";
      this.filter.endDate = "";

      this.page = 1;
      this.query = "?page=" + this.page;
      this.currentPage = 1;
      this.loading = true;
      this.getPengiriman();
    },

    //----------------- Fungsi Pindah Halaman dan Fungsi Lain -----------------

    toggleBusy() {
      if (this.isBusy == true) this.isBusy = false;
      else this.isBusy = true;
    },

    updateTotalItem(total) {
      this.perPage = this.size;
      this.rows = total;
      if (this.limit > this.rows) limit = this.rows;
      this.totalViewText =
        "Menampilkan " +
        this.from +
        " - " +
        this.limit +
        " dari " +
        this.rows +
        " entri";
    },
  },

  created() {
    this.init();
  },
};
</script>
